<template>
  <div :class="`float-btn ${position} ${direction}`" @click="onClick">
    <svgicon :name="`keyboard_arrow_${direction}`" width="40px" height="40px" />
  </div>
</template>

<script>
export default {
  name: 'FloatingBtn',
  props: {
    direction: {type:String, default: 'left'}, // left or right
    position: {type:String, default: 'fixed'} // fixed or absolute
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style';
.float-btn {
  cursor: pointer;
  display: block;
  width: 70px;
  height: 70px;
  top: 50%;
  margin-top: -35px;
  padding: 15px;
  background-color: rgba($info, 0.3);
  border-radius: 3rem;
  color: #fff;
  animation: fadeout-btn .3s;
}
.float-btn.fixed {
  position: fixed;
}
.float-btn.absolute {
  position: absolute;
}
.float-btn.left {
  left: 5%;
}
.float-btn.right {
  right: 5%;
}
.float-btn:hover {
  animation: fadein-btn .3s;
  background-color: rgba($info, .9);
}
.float-btn:active {
  background-color: rgba($info, .6);
}
@keyframes fadein-btn {
  from {
    background-color: rgba($info, .3);
  }
  to {
    background-color: rgba($info, .9);
  }
}
@keyframes fadeout-btn {
  from {
    background-color: rgba($info, .9);
  }
  to {
    background-color: rgba($info, .3);
  }
}
</style>